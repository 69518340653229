<template>
  <div>
      <v-card class="white pt-0 elevation-6" style="border-radius: 10px" >
          <v-layout row wrap>
              <v-flex xs12 class="pa-2 primary darken-2">            
                  <p class="white--text pa-0 ma-0 subheading text-xs-center"><b> Contact Our Support Team 24/7 </b></p>
              </v-flex>
              <v-flex xs12 md6 class="pa-4">            
                  <v-layout row>
                      <v-flex xs2 sm1 class="text-xs-left">
                          <i class="teal--text fas fa-lightbulb fa-2x"></i>
                      </v-flex>
                      <v-flex xs9 sm10>
                        <v-text-field class="pa-0 mt-0"
                            id="subject" ref="subject" v-model="subject"
                            label="Subject"
                            color="teal"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs1 >
                          <v-btn @click.stop="$store.commit('activateDialog', true); subject_help = true; getHelpMessage()" flat fab small outline color="black" class="my-2" style="height:20px; width: 20px" >
                              <i class="fas fa-question"> </i>                            
                          </v-btn>
                      </v-flex>
                  </v-layout>
                  <v-layout row>
                      <v-flex xs2 sm1 class="text-xs-left">
                          <i class="teal--text fas fa-layer-group fa-2x "> </i>                            
                      </v-flex>
                      <v-flex xs9 sm10>
                        <v-select
                          ref="category" id="category"
                          v-model="category" class="mt-0 pt-0 ml-1"
                          :items="categoryItems"
                          :rules="[v => !!v || 'Your Inquiry\'s Category is required']"
                          label="Your Inquiry's Category"
                          color="teal"
                          required
                        ></v-select>
                      </v-flex>
                      <v-flex xs1 >
                          <v-btn @click.stop="$store.commit('activateDialog', true); inquiry_help = true; getHelpMessage()" flat fab small outline color="black" class="my-2" style="height:20px; width: 20px" >
                              <i class="fas fa-question"> </i>                            
                          </v-btn>
                      </v-flex>
                  </v-layout>
                  <v-layout row>
                      <v-flex xs2 sm1 class="text-xs-left mt-2">
                          <i class="teal--text fas fa-comment fa-2x"> </i>                            
                      </v-flex>
                      <v-flex xs9 sm10>
                            <v-textarea class=" pa-0 mt-0 ml-1"
                                name="input-7-1" 
                                color="teal"
                                auto-grow  v-model="message"
                                ref="message"
                                label="Message"
                                :rules="[v => !!v || 'Message is required']"
                                background-color=" lighten-4"
                                rows="2" box
                                row-height="20" 
                            ></v-textarea>
                      </v-flex>
                      <v-flex xs1 >
                          <v-btn @click.stop="$store.commit('activateDialog', true); message_help = true; getHelpMessage()" flat fab small outline color="black" class="mt-3 ml-2" style="height:20px; width: 20px" >
                              <i class="fas fa-question"> </i>                            
                          </v-btn>
                      </v-flex>
                  </v-layout>
                  <v-layout row>
                      <v-flex xs11 >
                          <div class="mt-0" style="border-bottom: 1px solid grey; width: 100%; cursor: pointer"  @click="$refs.file.click()">
                              <input @change="fileInput()" type="file" ref="file" name="file" id="file" class="inputfile" accept=".jpg,.png,.jpeg,.pdf" style=""/>
                              <label class="teal" for="file"><strong><i class="fas fa-upload "> </i> Upload</strong></label>
                              <span class="text-xs-center" style="font-size: 12px; width: 100%">  {{inputFileName}}</span>
                          </div>
                      </v-flex>
                      <v-flex xs1 >
                          <v-btn @click.stop="$store.commit('activateDialog', true); file_help = true; getHelpMessage()" flat fab small outline color="black" class="mt-2 ml-2" style="height:20px; width: 20px" >
                              <i class="fas fa-question"> </i>                            
                          </v-btn>
                      </v-flex>
                  </v-layout>

                  <div class=" text-xs-center">
                      <v-btn small class="white--text elevation-6" round color="teal darken-2" @click="newSupportTicket()">
                          <span> Submit</span>
                      </v-btn>
                  </div>
              </v-flex>
              <v-flex xs12 md6 class="pa-4">            
                  <h4 class="teal--text headline"><b>Contact Information</b></h4>
                  <div style="">
                      <p class="text-dark">If you have any doubts, questions or proposals, you can either contact us using the information provided or fill the form to send a message. After getting your message, we shall contact you to solve the problem.</p>
                  </div>
                  <div class="contact_info_location">
                      <div class="contact_info_location_title">Yaounde Office</div>
                      <ul class="location_list">
                          <li><b>TECHNIPOLE</b>, NATIONAL ADVANCED SCHOOL OF ENGINEERING YAOUNDE I</li>
                          <li><b>Phone 1:</b>  +(237) 674028140</li>
                          <li><b>Phone 2:</b>  +(237) 675619330</li>
                          <li><a class="hiddenMail" data-email="ferencATdenesDOTcom"><b>Email: </b> pettycashforall@gmail.com</a></li>
                      </ul>
                  </div>
              </v-flex>
          </v-layout>
      </v-card>
  </div>
</template>

<script>
    import axios from 'axios'

    export default {
      data: () => ({
        inputFileName: "Select Accompanying File",
        valid: true,
        subject: '',
        message: '',
        category: '',
        file: '',
        subject_help: false,
        inquiry_help: false, 
        message_help: false,
        file_help: false,

        categoryItems: [
          'Technical',
          'Malfunction',
          'Advice',
          'Others'
        ],
        checkbox: false
      }),

      mounted(){
          // executes these after the page has been mounted
          document.title = "PettyCash Monitor | New Contact Us Message"
      },

      methods: {
        validate () {
          if (this.$refs.form.validate()) {
            this.snackbar = true
          }
        },
        reset () {
          this.$refs.form.reset()
        },
        resetValidation () {
          this.$refs.form.resetValidation()
        },

        fileInput(){
            var File= document.getElementById('file')
            if(File.files.item(0)){
                var FileName = File.files.item(0).name
                var FileSize = File.files.item(0).size
                var FileType = File.files.item(0).type
                console.log(FileType)
                FileName = FileName.replace(/.*[\/\\]/, '_')  // https://stackoverflow.com/questions/3437786/get-the-size-of-the-screen-current-web-page-and-browser-window
                var win = window,
                    doc = document,
                    docElem = doc.documentElement,
                    body = doc.getElementsByTagName('body')[0],
                    screenWidth = win.innerWidth || docElem.clientWidth || body.clientWidth,
                    screenHeight = win.innerHeight|| docElem.clientHeight|| body.clientHeight;
                
                if (FileSize >= 5242880){
                    this.$store.commit('setSnackBarMessage', "Your accompanying file exceeded the size limit of 5Mb. Upload a file less than 5 megabytes")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.file.focus()  // this causes a an auto fucos to the element
                    this.inputFileName = "Select Accompanying File"
                }
                else if(!FileType.includes("pdf") && !FileType.includes("jpeg") && !FileType.includes("jpg") && !FileType.includes("png") && !FileSize.includes("doc")){
                    this.$store.commit('setSnackBarMessage', "Your accompanying file must of format PDF, Image (.png, .jpeg, .jpg) or a file of format .doc")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.file.focus()  // this causes a an auto fucos to the element
                    this.inputFileName = "Select Accompanying File"
                }
                else{
                    if (FileName.length > 20){
                        if (screenWidth <= 760){
                            FileName = FileName.substring(0, 10) + '...' + FileName.substring(FileName.length-9, FileName.length) 
                        }
                    }
                    this.inputFileName = FileName
                    this.file = this.$refs.file.files[0]
                }
            }
            else{
                this.inputFileName = "Select Accompanying File"
            }
        },

        getHelpMessage(){
            if (this.subject_help){
                this.subject_help = false
                this.$store.commit('setDialogTitle', "Subject Name")
                this.$store.commit('setDialogMessage', "Please input your main reason for contacting us. A short phrase describing the reason for this messsage.")
                this.$store.commit('activateDialog', true)
            }else if (this.inquiry_help){
                this.inquiry_help = false
                this.$store.commit('setDialogTitle', "Inquiry Category")
                this.$store.commit('setDialogMessage', "Under which section or field does your message or question fall.")
                this.$store.commit('activateDialog', true)
            }else if (this.message_help){
                this.message_help = false
                this.$store.commit('setDialogTitle', "Message")
                this.$store.commit('setDialogMessage', "You can type in the message here.")
                this.$store.commit('activateDialog', true)
            }else if (this.file_help){
                this.file_help = false
                this.$store.commit('setDialogTitle', "Accompanying Files")
                this.$store.commit('setDialogMessage', "Any images/screenshots that may help in us better understand your message can be uploaded here.")
                this.$store.commit('activateDialog', true)
            }
        },

        async newSupportTicket(){
            var File= document.getElementById('file')

            if(this.subject === ""){
                this.$store.commit('setSnackBarMessage', "Please fill in the subject")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
                this.$refs.subject.focus()  // this causes a an auto fucos to the element
            }
            else if(this.category === ""){
                this.$store.commit('setSnackBarMessage', "Please select the message category")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
                this.$refs.category.focus()  // this causes a an auto fucos to the element
            }
            else if(this.message == ""){
                this.$store.commit('setSnackBarMessage', "Please fill in the message you want to send")
                this.$store.commit('setSnackBarColor', "red darken-4")
                this.$store.commit('activateSnackBar', true)
                this.$refs.campaign_target_amount.focus()  // this causes a an auto fucos to the element
            }
            else {
                this.$store.commit('setIsLoading', true)
            
                let formData = new FormData()
                formData.append('file', this.file)
                formData.append('subject', this.subject)
                formData.append('category', this.category)
                formData.append('message', this.message)
                console.log(this.file)

                await axios
                    .post('/api/v1/home/Monitor/create/new_ticket/', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        this.$store.commit('setSnackBarMessage', response.data[0].text)
                        this.$store.commit('setSnackBarColor', response.data[0].color)
                        this.$store.commit('activateSnackBar', true)
                        if(response.data[0].id !== 0){
                            this.$router.push('/monitor/contact_us/all/')
                        }
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            }

        },
      }
    }
</script>

<style scoped>
    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
    .inputfile + label {
        font-size: 14px;
        padding: 5px;
        font-weight: 700;
        color: white;
        background-color: rgb(0, 26, 255);
        display: inline-block;
    }

    .inputfile:focus + label,
    .inputfile + label:hover {
        background-color: rgb(55, 67, 230);
    }
    .inputfile + label {
        cursor: pointer; /* "hand" cursor */
    }
    .inputfile:focus + label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
    }
    .inputfile + label * {
        pointer-events: none;
    }

</style>

